import React from "react";
const Chains = {
    'BEP20': {
        'name': 'Smart Chain - Testnet',
        'chainId': '0x61',
        'uri': 'https://data-seed-prebsc-1-s1.binance.org:8545',
        'bexp': 'https://testnet.bscscan.com',
        'symbol': 'BNB',
        'decimals': 18,
    },

    'ERC20': {
        'name': 'Goerli test network',
        'chainId': '0x5',
        'uri': 'https://goerli.infura.io/v3/',
        'bexp': 'https://goerli.etherscan.io',
        'symbol': 'ETH',
        'decimals': 8,
    },
}
export default Chains;