import { React, useRef, useState } from 'react';
import './style.scss';
import img1 from "../assets/images/contact_bg.png";
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
    const capkey = "6LfAogcmAAAAAM466js4fCL2HrajauuigOJwlZJh";
    const [captcha, setCaptch] = useState("");
    const captchaRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let first_name = document.getElementById("first_name").value;
        let last_name = document.getElementById("last_name").value;
        let email = document.getElementById("email").value;
        let subject = document.getElementById("subject").value;
        let message = document.getElementById("message").value;

        // success bildiriminin silinmesi
        localStorage.removeItem('success');

        // API'ye istek gönderme
        const response = await fetch( process.env.REACT_APP_API + '/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ first_name, last_name, email, subject, message, captcha })
        });
  
        // İstek başarılıysa
        const data = await response.json();
        
        var msgBox = document.getElementById('resp-result');

        if( data.status == "success" ){
            msgBox.innerHTML = data.message;
            msgBox.style.color = "rgb(86 255 119 / 99%)";
        }else{
            msgBox.innerHTML = data.message;
            msgBox.style.color = "#ff5656fc";
        }
    }

    return (
        <div className='contact'>
            <section className="breadcrumb-area" style={{
                backgroundImage: `url(${img1})`,

            }}>
                <div className="container">
                    <div className="breadcrumb__wrapper">
                        <div className="breadcrumb__content">
                        </div>
                    </div>
                </div>
            </section>


            <section className="services-area services__bg-color section-pt-120 section-pb-120 mt-5">
                <div className="container">
                    <div className="row align-items-end align-items-xl-start">
                        <div className="col-lg-8">
                            <div className="section__title text-start mb-65">
                                <h3 className="title">Contact Us</h3>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="name"><b>First Name</b></label>
                                <input required type="text" id="first_name" className="form-control" />
                            </div>

                            <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="name"><b>Last Name</b></label>
                                <input required type="text" id="last_name" className="form-control" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="name"><b>Email</b></label>
                                <input required type="text" id="email" className="form-control" />
                            </div>

                            <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="name"><b>Subject</b></label>
                                <input required type="text" id="subject" className="form-control" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="name"><b>Message</b></label>
                                <textarea className="form-control" id="message" placeholder="Type your message..."></textarea>
                            </div>
                            <div className="col-md-6">
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={capkey}
                                    size="normal"
                                    hl="en"
                                    theme="dark"
                                    onChange={() => {
                                        setCaptch(captchaRef.current.getValue());
                                    }}
                                    style={{ width:"100%", padding: "4%" }}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                                <div className="col-md-6">
                                    <button className="tf-button-st2 btn-effect" id="send_message"> <span className="effect">Send</span></button>
                                </div>
                            </div>
                        </form>
                        <p className="ajax-response" id='resp-result' style={{ color: "#ff5656fc"}}> </p>
                    </div>
            </section>
        </div>

    );
}
export default Contact;