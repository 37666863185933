import { React, useState, useEffect } from "react";
import './index.scss';
import img1 from '../assets/images/about_header_bg.png';
import img2 from '../assets/images/background/maf_img_135.png';

const AboutUs = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
    }, []);

 

    return (
        <div className='about'>
            <section className="breadcrumb-area" style={{
                backgroundImage: `url(${img1})`,

            }}>
                <div className="container">
                    <div className="breadcrumb__wrapper">

                    </div>
                </div>
            </section>


            <section className="services-area services__bg-color section-pt-120 section-pb-120 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb__content">
                                <nav aria-label="breadcrumb">

                                </nav>
                                <br />
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <h4>What is the Metaverse Army Forces?</h4>
                                        <p>Metaverse Army Forces is an immersive war game set between planets, where alien forces attempt to invade while super soldiers defend their world. The game features intense battles, unpredictable moments, and emphasizes teamwork. Players can choose to be aliens or humans and earn tokens by leveraging the gaming and DeFi mechanism as they progress.<br/>
                                        </p>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                    </div>
                    <div className="row align-items-end align-items-xl-start mt-10">
                        <div className="col-lg-8">
                            <div className="section__title text-start mb-65">
                                <h3 className="title">The State-of-the-art</h3>
                            </div>
                            <div className="services__wrapper">
                                <div className="services__item">
                                    <div className="services__icon">
                                        <i className="fi fi-rr-gem"></i>
                                    </div>
                                    <div className="services__content">
                                        <h4 className="title"><a href="services-details.html">Dedicated & Expert Team</a></h4>
                                        <p>MAF team is meticulously crafted in order to provide the best of bests.</p>
                                    </div>
                                </div>
                                <div className="services__item">
                                    <div className="services__icon">
                                        <i className="fi fi-sr-user"></i>
                                    </div>
                                    <div className="services__content">
                                        <h4 className="title"><a href="services-details.html">Powerful Knowledge</a></h4>
                                        <p>MAF team consists of blockchain and finance experts.</p>
                                    </div>
                                </div>
                                <div className="services__item">
                                    <div className="services__icon">
                                        <i className="fi fi-brands-ethereum"></i>
                                    </div>
                                    <div className="services__content">
                                        <h4 className="title"><a href="services-details.html">The First Fun To Play</a></h4>
                                        <p>MAF goes far beyond being a play-to-earn game. It is also fun-to-play game.</p>
                                    </div>
                                </div>
                                <div className="services__item">
                                    <div className="services__icon">
                                        <i className="fi fi-rr-settings-sliders"></i>
                                    </div>
                                    <div className="services__content">
                                        <h4 className="title"><a href="services-details.html">Worldwide Web</a></h4>
                                        <p>Crypto and gaming enthusiasts have no boundaries, and neither does MAF. Play MAF with players all around the world.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img src={img2} alt="img" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;