
const menus = [
    {
        id : 1,
        name : "Home",
        links : "/"
    },
    {
        id : 2,
        name : "About Us",
        links : "/about-us"
    },
    {
        id : 3,
        name : "Items",
        links : "#",
        namesub : [
            {
                id : 1,
                sub : "Skins",
                links : "/comingsoon"
            },
            {
                id : 2,
                sub : "Weapons",
                links : "/comingsoon"
            },
        ]
    },
    
    {
        id : 4,
        name : "FAQ",
        links : "/faq"
    },
    {
        id : 5,
        name : "Presale",
        links : "/presale"
    },
    {
        id : 5,
        name : "Contact Us",
        links : "/contact"
    },
];
export default menus;