import React from "react";
import Button from "../../Components/button";
import './index.scss';

const SectionJoin = () => {
    return (
        <section className="tf-section join_community">
            <div className="container" style={{
                textAlign:'center',
                padding:'12px'
            }}>
                <div style={{
                    display: 'inline-block',
                    margin: "0 auto",
                    justifyContent:'center',
                    alignItems:'center',
                    alignContent:'center'
                }}>
                    <h3>JOIN THE</h3><br/>
                    <h3 style={{
                        color : '#14C2A3',
                    }}>COMMUNITY</h3>
                    <p>Join our Discord community to gather with your allies!</p>
                    <Button path="https://discord.gg/tnTSzUdj" target="_blank" title="JOIN DISCORD" />
                </div>
            </div>
        </section>
    );
}
export default SectionJoin;