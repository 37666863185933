import { React, useEffect, useState } from "react";
import './style.scss';
import AccordionItem from "./AccordionItem";
import img1 from '../assets/images/background/breadcrumb_img01.png';
import img2 from '../assets/images/background/maf_img_135.png';
const FAQ = () => {
    const [dataFaq, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API + '/faqs');
            const jsonData = await response.json();

            if (jsonData.status == "success") {
                setData(jsonData.data);
            }
        } catch (error) {
            console.log('Veri alınamadı:', error);
        }

        console.log(dataFaq)
    };
    return (
        <div>
            <section className="breadcrumb-area" data-background={img1}>
                <div className="container">
                    <div className="breadcrumb__wrapper">
                        <div className="row">
                            <div className="breadcrumb__content">
                                <nav aria-label="breadcrumb">
                                    <h6 style={{textAlign:'center'}}>Get to know us more through the journey!</h6>
                                </nav>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section faq bg-st2">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="flat-accordion" data-aos="fade-up" data-aos-duration="800">
                                {
                                    dataFaq.slice(0, dataFaq.length / 2).map(item => (
                                        <AccordionItem key={item.id} item={item} />
                                    ))
                                }

                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="flat-accordion" data-aos="fade-up" data-aos-duration="800">
                                {
                                    dataFaq.slice(dataFaq.length / 2, dataFaq.length * 2).map(item => (
                                        <AccordionItem key={item.id} item={item} />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default FAQ;