import React from "react";
import './style.scss';
import '../../scss/components/section.scss';
import '../../scss/components/box.scss';
import video from '../../assets/video/intro_video.mp4'
const SectionVideo = () => {
    return (
        <div className='home-1'>
            <section className="slider" style={{
                position: 'relative',
                height: '100vh',
            }}>
                <video playsInline autoPlay muted loop poster="polina.jpg" className="slider">
                    <source src={video} type="video/mp4" />
                </video>
                <div className="slider-activee">
                    <div className="single-slider" >
                        <div className="container custom-container">
                            <div className="row justify-content-between">
                                <div className="col-lg-12">
                                    <div className="slider__content">
                                        <h6 className="sub-title wow fadeInUp" data-wow-delay=".2s">Conquer to Survive</h6>
                                        <h2 className="title wow fadeInUp" data-wow-delay=".5s">MAF</h2>
                                        <p className="wow fadeInUp" data-wow-delay=".8s">Metaverse Army Forces</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default SectionVideo;
